


















































































































import Vue from 'vue';
import 'vue-select/dist/vue-select.css';
import { recursiveFillData } from '@/helper/functions';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'individual-insurance-basic',
  mixins: [dataChangeMixin],
  data() {
    return {
      name: 'individual-insurance-basic',
      key: 'INDIVIDUAL_INSURANCE',
      vertragsart: '',
      anschriftNeubau: {
        Auswahl: '',
        Strabe: '',
        PLZ: '',
        Ort: '',
        Land: '',
        Zurs: '',
        Naturgefahrenklasse1: '',
        Naturgefahrenklasse2: '',
        Naturgefahrenklasse3: '',
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData'],
  watch: {
    vertragsart: {
      handler(newValue, oldValue) {
        if (newValue === 'MaschineFahrbar' || newValue === 'MaschineStationar') {
          this.key = 'INDIVIDUAL_INSURANCE';
          this.$emit('on-key-change', this.key);
        }
        if (newValue === 'Kraftwerke') {
          this.key = 'MACHINES_POWER_PLANTS_INSURANCE';
          this.$emit('on-key-change', this.key);
        }
      },
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
  },
  methods: {
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        this.vertragsart = this.insuranceFormsApiData.basicData.vertragsart;
        recursiveFillData(this.anschriftNeubau, this.insuranceFormsApiData.basicData.anschriftNeubau);
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData.basicData;
      this.vertragsart = calculationData.vertragsart;
      this.anschriftNeubau = calculationData.anschriftNeubau;
    },
    onSubmit(goNextPage = true) {
      const data = {
        vertragsart: this.vertragsart,
        anschriftNeubau: this.anschriftNeubau,
      };
      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    titleTab(): string {
      return 'Versicherungsort';
    },
  },
});
